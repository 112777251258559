import { Observable } from 'rxjs';
import { PagedRequest } from '../paged-request';
import { ShowCategoryResponse } from './read-category.dto';
import { GetCategoryResponse, ListCategoryQuery, ListCategoryResponse } from './list-category.dto';
import { WriteCategoryPayload, WriteCategoryResponse } from './write-category.dto';

export abstract class CategoryRepository implements PagedRequest {
  abstract show(id: number): Observable<ShowCategoryResponse>;

  abstract get(query?: any): Observable<GetCategoryResponse>;

  abstract list(query?: ListCategoryQuery): Observable<ListCategoryResponse>;

  abstract create(payload: WriteCategoryPayload): Observable<WriteCategoryResponse>;

  abstract update(payload: WriteCategoryPayload): Observable<WriteCategoryResponse>;
}
